import React, { Fragment } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ErrorMessage from "elements/ErrorMessage"
import { hasIncompleteValues } from "../services/form"

const PatientInformationSummary = ({ patientEnrollment, addressIcon }) => {
  if (
    hasIncompleteValues({
      data: patientEnrollment,
      flow: "patientEnrollmentPersonalInformation",
    })
  ) {
    return (
      <ErrorMessage message="Some required information is missing or incomplete. Please double check if you have filled out the required fields." />
    )
  }

  return (
    <Fragment>
      <p className="has-text-weight-bold">
        {patientEnrollment.firstName}
        {patientEnrollment.middleInitial
          ? ` ${patientEnrollment.middleInitial}. `
          : ""}{" "}
        {patientEnrollment.lastName}
      </p>
      <p>
        {patientEnrollment.mobileNumber} | {patientEnrollment.emailAddress}
      </p>
      <p>
        <span className="icon is-small">
          <FontAwesomeIcon icon={addressIcon} />
        </span>{" "}
        {patientEnrollment?.patientAddress.streetAddress},{" "}
        {patientEnrollment?.patientAddress.city.value},{" "}
        {patientEnrollment?.patientAddress.province.value}
      </p>
      <p>
        Birthday:{" "}
        <span className="has-text-weight-bold">
          {patientEnrollment.birthday.month.value}{" "}
          {patientEnrollment.birthday.date.value},{" "}
          {patientEnrollment.birthday.year}
        </span>
      </p>

      {patientEnrollment?.landlineNumber && (
        <p>
          Landline:{" "}
          <span className="has-text-weight-bold">
            {patientEnrollment?.landlineNumber}
          </span>
        </p>
      )}
      <p className="mt-1">
        Secondary Contact Person:{" "}
        <span className="has-text-weight-bold">
          {patientEnrollment?.secondaryContactFullName}
        </span>
      </p>
      <p>
        Mobile Number:{" "}
        <span className="has-text-weight-bold">
          {patientEnrollment?.secondaryContactMobileNumber}
        </span>
      </p>
    </Fragment>
  )
}
export default PatientInformationSummary
