import * as Yup from "yup"

export const consentAndAuthValidationSchema = Yup.object().shape({
  agreeToConsent: Yup.array().min(1, "This field is required"),
  submittingUserType: Yup.string().required("This field is required"),
})

export const doctorEnrollmentConsentAndAuthValidationSchema = Yup.object().shape(
  {
    agreeToConsent: Yup.array().min(1, "This field is required"),
  }
)

export const consentAndAuthInitialValues = {
  agreeToConsent: [],
  agreeToTermsAndConditions: [],
  submittingUserType: "",
}
